import React from "react"
import { FormattedMessage } from "react-intl"
import axios from "axios"
import LocalizedLink from "./localized-link"
import "./blog-list.css"

class BlogList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      posts: [],
      lang: 'en'
    }
    console.log(props)
    
  }
  setLang(){
    let lang  = 'en'
    if(typeof window !== "undefined"){
      let pathLang = window.location.pathname.split('/')[1]
      if(pathLang){
        lang = pathLang || 'en'
      }
    }
    this.setState({
      lang: lang
    })
  }
  componentWillMount() {
    this.setLang()
  }
  componentDidMount() {
    axios
      .get(`${process.env.API_ADDRESS}/posts?lang=${this.state.lang == 'zh' ? 'zh': 'en'}`)
      .then(resp => {
        this.setState({
          posts: resp.data,
        })
      })
      .catch(e => {
        console.log(e)
      })
  }
  formatterTimer = (time) => {
    var d = new Date(time);
    let month = d.getMonth() + 1
    month = month < 10 ? '0' + month : month
    let date = d.getDate()
    date = date < 10 ? '0' + date : date
    return d.getFullYear() + '/' + month + '/' + date
  }
  render() {
    return (
      <div className="container blog-container">
        {this.state.posts.map(post => {
          return (
            <div className="blog-content" key={post._id}>
              

              <div className="blog-coverImage">
                <LocalizedLink
                  to={`/blog/${post.slug || post._id}`}
                >
                  <img src={post.coverImage} alt="MASS,区块链共识引擎,POC,存储空间租赁,容量证明,共识引擎,挖矿" />
                </LocalizedLink>
              </div>
              <div className="blog-detail">
                <LocalizedLink
                  className="blog-content-title"
                  to={`/blog/${post.slug || post._id}`}
                >
                  {post.title}
                </LocalizedLink>
                <div className="blog-content-time">
                  <span>
                    {/* <FormattedMessage id="org.massnet.components.blog.time" />{" "} */}
                    {this.formatterTimer(post.createdAt)}
                  </span>
                </div>
                {/* <p className="blog-content-desc ellipsis">{ this.formatterDesc(post.desc)}</p> */}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}
export default BlogList
